import { Api } from "@citrite/sf-api";
import type { PiletApi } from "@sharefiledev/sharefile-appshell";
import { Skeleton } from "antd";
import React from "react";

import { AppRouteProps } from "./routes/AppRoute";
import { setLogger } from "./util/logger";

const LazyAppRoute = React.lazy(() => import("./routes/AppRoute"));
const AsyncLazyAppRoute: React.FC<AppRouteProps> = (props) => {
  return (
    <React.Suspense fallback={<Skeleton />}>
      <LazyAppRoute {...props} />
    </React.Suspense>
  );
};

export const setup = (app: PiletApi) => {
  setLogger(app.sf.getLogger());

  configureApiClients(app);
  app.registerExtension(
    "urn:sfextslot:sharefile-appshell:settings:admin-settings:tenant-management",
    (props) => <AsyncLazyAppRoute app={app} {...props.params} />
  );
};

const configureApiClients = (app: PiletApi) => {
  app.sf.registerSfApiConfigHandler(Api.configure);
};
