import { PiletScopedLogger } from "@sharefiledev/sharefile-appshell";

const loggerNotSetup = () => new Error("Logger not setup");

export let logger: PiletScopedLogger = {
  logError: loggerNotSetup,
  logInfo: loggerNotSetup,
  setLoggingTags: loggerNotSetup,
};

export const setLogger = (piletLogger: PiletScopedLogger) => {
  logger = piletLogger;
};
